import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import { navigate } from 'gatsby'

import firebase from 'firebase/app'
import { getAuth } from 'firebase/auth'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import * as stripe from '@stripe/stripe-js'
import StripeCardForm, {
  FormChangedEvent,
  FormValues as StripeCardFormValues,
} from './stripe/stripe-card-form'
import { Elements } from '@stripe/react-stripe-js'

import * as api from '../utils/api'
import * as types from '../utils/types'

import { SiteConfig } from '../utils/types'
/* eslint-disable @typescript-eslint/no-var-requires */
const { siteMetadata }: SiteConfig =
  require('../../gatsby-config.ts') as SiteConfig
/* eslint-enable @typescript-eslint/no-var-requires */

const PricingCard = () => {
  const cssClasses = makeStyles({
    title: {
      fontSize: 20,
      'text-align': 'center',
      'margin-bottom': '0.8em',
    },
    price: {
      'text-align': 'right',
      fontSize: 30,
    },
    perMonth: {
      fontSize: 12,
    },
    baseStats: {
      fontSize: 14,
      'vertical-align': 'middle',
    },
    excessStats: {
      'text-align': 'center',
      'margin-top': '10px',
      fontSize: 12,
    },
  })()

  return (
    <Grid container spacing={3}>
      <Grid item xs />
      <Grid item xs={10} sm={6} md={4}>
        <Card variant="outlined">
          <CardContent>
            <Typography className={cssClasses.title}>Standard Plan</Typography>
            <Grid item container spacing={0}>
              <Grid item xs md={4}>
                <Typography className={cssClasses.price}> $5 </Typography>
              </Grid>
              <Grid item xs={2}>
                <Grid item container spacing={0}>
                  <Grid item xs={12}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={cssClasses.perMonth}>
                      / Mo.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs md={6}>
                <Typography className={cssClasses.baseStats}>
                  20GB Storage
                </Typography>
                <Typography className={cssClasses.baseStats}>
                  5GB Transfer
                </Typography>
              </Grid>
            </Grid>
            <Typography className={cssClasses.excessStats}>
              20¢ / GB of Storage or Transfer over the limit
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs />
    </Grid>
  )
}

type CreateAccountProps = {
  firebase: firebase.FirebaseApp
}

const CreateAccount: FunctionComponent<CreateAccountProps> = ({
  firebase: firebaseApp,
}) => {
  const [user, setUser] = React.useState(getAuth(firebaseApp).currentUser)

  // Check if we've already signed up and have a proper account.
  const [isSignedUp, setIsSignedUp] = React.useState<boolean | undefined>(
    undefined,
  )
  React.useEffect(() => {
    const asyncEffect = async () => {
      if (!user) {
        console.warn('No user authenticated')
        return
      }

      let patronIsRegistered = false
      try {
        patronIsRegistered = await api.isPatronRegistered(firebaseApp)
      } catch (error) {
        console.error(
          'An error occurred while verifying patron registration status',
          error,
        )
      }
      if (!patronIsRegistered) {
        await navigate('/sign-up/register')
      }

      let accountId
      try {
        // TODO(alex): Handle multiple accounts.
        accountId = await api.getAccountId(firebaseApp)
      } catch (error) {
        console.debug('An error occurred while getting account ID', error)
        setIsSignedUp(false)
      }

      if (accountId && accountId !== types.None) {
        // TODO(alex): Actually use account information to display useful info
        // to the user.  This isn't strictly necessary until a user can have
        // multiple accounts though....
        setIsSignedUp(true)
      } else {
        setIsSignedUp(false)
      }
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged((user) => {
      setUser(user)
      void asyncEffect()
    })

    return () => {
      unsubscribe()
    }
  }, [user, firebaseApp])

  React.useEffect(() => {
    const asyncEffect = async () => {
      if (isSignedUp === undefined) {
        return
      }

      if (isSignedUp) {
        await navigate('/admin')
      }
    }

    void asyncEffect()
  }, [isSignedUp])

  // TODO(alex): If previously entered information into this form, repopulate
  //             it.

  // Just get stripePromise once
  const [stripePromise, setStripePromise] = React.useState<
    Promise<stripe.Stripe | null> | undefined
  >(undefined)
  React.useEffect(() => {
    setStripePromise(stripe.loadStripe(siteMetadata.config.stripeConfig.apiKey))
  }, [])

  const [cardInfo, setCardInfo] = React.useState<
    StripeCardFormValues | undefined
  >(undefined)
  const [formIsValidAndComplete, setFormIsValidAndComplete] =
    React.useState(false)

  const [currentlyCreatingAccount, setCurrentlyCreatingAccount] =
    React.useState(false)

  const onFormChanged = React.useCallback(
    (event: FormChangedEvent) => {
      setFormIsValidAndComplete(event.complete)
      if (event.complete) {
        setCardInfo({
          name: event.values.name,
          cardElement: event.values.cardElement,
          stripe: event.values.stripe,
        })
      }
    },
    [setFormIsValidAndComplete, setCardInfo],
  )

  return (
    <>
      <Helmet title="Set up account payment for HostBurro" />
      <h1>Set up account payment</h1>

      <PricingCard />

      {!stripePromise ? (
        <CircularProgress />
      ) : (
        <>
          <form
            onSubmit={(e) => {
              const submitter = async () => {
                if (cardInfo === undefined) {
                  console.error(
                    'Card info was not set before submission was attempted.',
                  )
                  return
                }

                // TODO(alex): If any step below fails, make sure it's actually
                // resumable.  Right now partial success will make this whole flow
                // fail on retry as it will prevent duplicates... but there's no
                // way to make sure the next step is alright to proceed with at
                // the moment even if it were.

                // TODO(alex): If creation fails, communicate next steps to the
                // patron.
                setCurrentlyCreatingAccount(true)
                let accountId
                try {
                  accountId = await api.createAccount(firebaseApp, 'Default')
                } catch (error) {
                  console.error('Account creation failed:', error)
                  return
                }

                // TODO(alex): attach project to explicitly requested
                // account instead of implicitly assuming there is only one.
                try {
                  await api.createProject(firebaseApp)
                } catch (error) {
                  console.error('Project creation failed:', error)
                  return
                }

                const stripeResult = await cardInfo.stripe.createPaymentMethod({
                  type: 'card',
                  card: cardInfo.cardElement,
                  billing_details: {
                    name: cardInfo.name,
                  },
                })
                if (stripeResult.error) {
                  console.error(
                    'Stripe payment method creation failed: ',
                    stripeResult.error,
                  )
                  return
                }

                const { id: paymentMethodId } = stripeResult.paymentMethod
                await api.subscribe(firebaseApp, accountId, paymentMethodId)

                await navigate('/admin')

                // This is here for symmetry
                setCurrentlyCreatingAccount(false)
              }

              void submitter()
              e.preventDefault()
              return false
            }}>
            <Elements stripe={stripePromise ?? null}>
              <StripeCardForm
                title="Payment Method"
                onFormChanged={onFormChanged}
              />
            </Elements>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                {currentlyCreatingAccount ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={true}
                    fullWidth>
                    <CircularProgress size={24} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!formIsValidAndComplete}
                    type="submit">
                    Start Subscription
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  )
}

export default CreateAccount
