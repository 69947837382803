import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import Link from 'gatsby-link'
import { navigate } from 'gatsby'

import firebase from 'firebase/app'
import { getAuth } from 'firebase/auth'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import * as api from '../utils/api'

type RegisterPatronProps = {
  firebase: firebase.FirebaseApp
}

const RegisterPatron: FunctionComponent<RegisterPatronProps> = ({
  firebase: firebaseApp,
}) => {
  // Check if we've already registered
  const [isRegistered, setIsRegistered] = React.useState<boolean | undefined>(
    undefined,
  )

  const [agreesToTermsOfService, setAgreesToTermsOfService] = React.useState<
    boolean | undefined
  >(undefined)
  const [agreesToPrivacyPolicy, setAgreesToPrivacyPolicy] = React.useState<
    boolean | undefined
  >(undefined)
  React.useEffect(() => {
    const asyncEffect = async () => {
      const patronId = getAuth(firebaseApp).currentUser?.uid
      if (!patronId) {
        return
      }

      let agreements
      try {
        agreements = await api.getPatronAgreements(firebaseApp)
      } catch (error) {
        // TODO(alex): Communicate failure to the patron.
        console.error('Getting patron agreements failed with error:', error)
        return
      }

      // The user must have been registered if they've already been recorded as
      // having agreed to the full terms and conditions.
      setIsRegistered(
        agreements.agreed_to_privacy_policy &&
          agreements.agreed_to_terms_of_service,
      )

      setAgreesToPrivacyPolicy(agreements.agreed_to_privacy_policy)
      setAgreesToTermsOfService(agreements.agreed_to_terms_of_service)
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged(() => {
      void asyncEffect()
    })

    return () => {
      unsubscribe()
    }
  }, [firebaseApp])

  // TODO(alex): If previously entered information into this form, repopulate
  //             it.

  // TODO(alex): Display checkout form

  // TODO(alex): Require agreement to ToS and Privacy Policy

  // TODO(alex): Create default project so user doesn't need to configure
  //             anything to have something to show for their signing up.

  const [currentlyRegisteringUser, setCurrentlyRegisteringUser] =
    React.useState(false)
  return (
    <>
      <Helmet title="Agree to HostBurro Terms and Conditions" />
      <h1>Sign Up</h1>

      <Typography variant="h6" gutterBottom>
        Terms and Conditions
      </Typography>
      <form
        onSubmit={(e) => {
          const submitter = async () => {
            if (isRegistered) {
              // TODO(alex): Push actual navigation control up to the sign-up
              // component.
              await navigate('/sign-up/payment')
            } else {
              // TODO(alex): If registration fails, communicate next steps to
              // the patron.
              setCurrentlyRegisteringUser(true)

              await api.registerPatron(
                firebaseApp,
                !!agreesToTermsOfService,
                !!agreesToPrivacyPolicy,
              )

              // TODO(alex): Push actual navigation control up to the sign-up
              // component.
              await navigate('/sign-up/payment')
              setCurrentlyRegisteringUser(false)
            }
          }

          void submitter()
          e.preventDefault()
          return false
        }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="termsOfService"
                  disabled={!!isRegistered}
                  checked={!!agreesToTermsOfService}
                />
              }
              onChange={(event) => {
                const checked = (
                  event.target as unknown as { checked: boolean }
                ).checked
                setAgreesToTermsOfService(checked)
              }}
              label={
                <>
                  I have read and agree to the{' '}
                  <Link to="/terms/terms_of_service">Terms of Service</Link>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="privacyPolicy"
                  disabled={!!isRegistered}
                  checked={!!agreesToPrivacyPolicy}
                />
              }
              onChange={(event) => {
                const checked = (
                  event.target as unknown as { checked: boolean }
                ).checked
                setAgreesToPrivacyPolicy(checked)
              }}
              label={
                <>
                  I have read and agree to the{' '}
                  <Link to="/terms/privacy_policy">Privacy Policy</Link>
                </>
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={6}>
            {currentlyRegisteringUser ? (
              <Button
                variant="contained"
                color="primary"
                disabled={true}
                fullWidth>
                <CircularProgress size={24} />
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={!agreesToTermsOfService || !agreesToPrivacyPolicy}
                fullWidth
                type="submit">
                {isRegistered ? 'Continue' : 'Register'}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default RegisterPatron
