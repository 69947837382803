import React, { FunctionComponent } from 'react'

import firebase from 'firebase/app'

import { WindowLocation, Router } from '@reach/router'

import DefaultLayout from '../layouts/index'

import FirebaseLoadable from '../components/firebase-loadable'
import PrivateRoute from '../components/private-route'

import SignUp from '../components/sign-up'

type SignUpPageProps = {
  location: WindowLocation
}

const SignUpPage: FunctionComponent<SignUpPageProps> = ({ location }) => {
  let resolver
  let rejecter
  const firebasePromise = new Promise<firebase.FirebaseApp>(
    (resolve, reject) => {
      resolver = resolve
      rejecter = reject
    },
  )
  const [firebaseApp, setFirebaseApp] = React.useState<
    firebase.FirebaseApp | undefined
  >(undefined)

  firebasePromise
    .then((fbApp: firebase.FirebaseApp) => {
      setFirebaseApp(fbApp)
    })
    .catch((reason) => {
      console.error(reason)
    })

  // TODO(alex): If not signed in, ask user sign in / create login, else provide
  // sign up / payment / etc. flow.
  return (
    <DefaultLayout location={location} firebase={firebaseApp}>
      <FirebaseLoadable resolver={resolver} rejecter={rejecter} />
      {!firebaseApp ? (
        <div>Initializing Firebase...</div>
      ) : (
        <Router basepath="/sign-up">
          <PrivateRoute
            location={location}
            path="/*"
            firebase={firebaseApp}
            component={SignUp}
          />
        </Router>
      )}
    </DefaultLayout>
  )
}

export default SignUpPage
