import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import { WindowLocation } from '@reach/router'

import { navigate } from 'gatsby'

import firebase from 'firebase/app'
import { getAuth } from 'firebase/auth'

import CircularProgress from '@material-ui/core/CircularProgress'

import * as api from '../utils/api'

import CreateAccount from '../components/create-account'
import RegisterPatron from '../components/register-patron'

type SignUpProps = {
  location: WindowLocation
  firebase: firebase.FirebaseApp
}

const SignUp: FunctionComponent<SignUpProps> = ({
  location,
  firebase: firebaseApp,
}) => {
  const [user, setUser] = React.useState(getAuth(firebaseApp).currentUser)

  const currentStepPath = location.pathname.split('/').pop()
  const [stableStepPath, setStableStepPath] = React.useState<
    string | undefined
  >(undefined)
  if (currentStepPath !== stableStepPath) {
    setStableStepPath(currentStepPath)
  }

  // Check if we've already registered
  const [isRegistered, setIsRegistered] = React.useState<boolean | undefined>(
    undefined,
  )
  React.useEffect(() => {
    const asyncEffect = async () => {
      if (!user) {
        console.warn('No user authenticated')
        return
      }

      try {
        setIsRegistered(await api.isPatronRegistered(firebaseApp))
      } catch (error) {
        console.error(
          'An error occurred while verifying patron registration status',
          error,
        )
        setIsRegistered(false)
      }
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged((user) => {
      setUser(user)
      void asyncEffect()
    })

    return () => {
      unsubscribe()
    }
  }, [user, firebaseApp])

  const [registrationComponent] = React.useState(
    <RegisterPatron firebase={firebaseApp} />,
  )
  const [paymentComponent] = React.useState(
    <CreateAccount firebase={firebaseApp} />,
  )

  const [currentStepComponent, setCurrentStepComponent] = React.useState<
    React.ReactNode | undefined
  >(undefined)
  React.useEffect(() => {
    if (isRegistered === undefined) {
      return
    }

    const asyncEffect = async () => {
      const availableSteps: Record<string, React.ReactNode> = {
        register: registrationComponent,
        payment: paymentComponent,
      }

      if (
        !currentStepPath ||
        !Object.prototype.hasOwnProperty.call(availableSteps, currentStepPath)
      ) {
        // TODO(alex): Set location without causing screen flash / jank
        if (!isRegistered) {
          await navigate('/sign-up/register')
          return
        } else {
          await navigate('/sign-up/payment')
          return
        }
      }

      setCurrentStepComponent(availableSteps[currentStepPath])
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged(() => {
      void asyncEffect()
    })

    return () => {
      unsubscribe()
    }
  }, [
    isRegistered,
    stableStepPath,
    firebaseApp,
    currentStepPath,
    location,
    registrationComponent,
    paymentComponent,
  ])

  return (
    <>
      <Helmet title="Sign up for HostBurro" />
      {currentStepComponent === undefined ? (
        <CircularProgress />
      ) : (
        currentStepComponent
      )}
    </>
  )
}

export default SignUp
